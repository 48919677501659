<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top: 80px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">关于我们</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">About the future +</p>
      </div>

      <div class="content">
        <div class="c-l">
          <el-image
            style="width: 447px; height: 257px"
            :src="require('../assets/platformp/ds.png')"
          ></el-image>
        </div>
        <div class="c-r">
          公司创立于2017年，总部位于中国杭州，是一家集科技研发、智能物联网应用、数字化及数据研发和应用的新型互联网企业，是一家高新技术企业。公司自创立以来，一直秉持“创新、共赢、共享、共同成就的经营理念，以“SaaS智慧系统、app、小程序(智慧物业)
          +仓储式超市+轻食+社区圈数字信rg息流为融合型模式为依托，迎合社会未来发展趋势打造一个智慧的“社区圈物业服务”“生活商业“社区圈精神娱乐生活”的未来“全民智慧生活生态圈。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  text-align: center;
  .mine {
    width: 80%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .content {
    text-align: left;
    margin-top: 180px;
    margin-bottom: 180px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .c-r {
      width: 434px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      margin-left: 130px;
    }
  }
}
</style>
